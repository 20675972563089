import React from 'react';
const classname = "youtube-contain";

export default class Youtube extends React.Component {
    render() {

        const { thecode } = this.props
        const content = `
        <iframe width="400" height="300" src="https://www.youtube.com/embed/${thecode}?rel=0" frameborder="0"></iframe>
    `
        return (
            <div className={classname} dangerouslySetInnerHTML={{ __html: content }} />
        )
    }
}    
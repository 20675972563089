import React from 'react';
const classname = "soundcloud-contain";


  export default class Soundcloud extends React.Component {
    render() {

        const { thecode } = this.props
        const content = `
                <iframe width="400" height="300" scrolling="no" frameborder="no" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${thecode}&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;visual=true"></iframe>
            `
        return (
            <div className={classname} dangerouslySetInnerHTML={{ __html: content }} />
        )
    }
}    